import { StorageProperties } from "@libs/constants/storage-properties.ts";
import { routes } from "@libs/routing/routes.ts";
import { Hotkey } from "@libs/utils/Hotkey.ts";

import { UserExperienceStore } from "./UserExperienceStore.tsx";

export interface HotkeyActions {
  [key: string]: (() => void) | undefined;
}

export enum HotkeyActionNames {
  openKB = "Knowledge base search",
  patientSearch = "General patient search",
  gotToAppointmentBook = "Open Appointment book",
  openWordProcessor = "Open Word processor",
  goToClinicalTasks = "Open Clinical tasks",
  goToInbox = "Open Inbox",
  refreshScreen = "Refresh screen",
  fullScreen = "Enter full screen mode"
}

export const browserDefaultHotkeys = ["f5", "f11"];

export const userDefualtHotkeys: Array<[string, HotkeyActionNames]> = [
  ["f1", HotkeyActionNames.openKB],
  ["f5", HotkeyActionNames.refreshScreen],
  ["f2", HotkeyActionNames.patientSearch],
  ["f6", HotkeyActionNames.goToClinicalTasks],
  ["f3", HotkeyActionNames.gotToAppointmentBook],
  ["f7", HotkeyActionNames.goToInbox],
  ["f4", HotkeyActionNames.openWordProcessor],
  ["f11", HotkeyActionNames.fullScreen]
];
export class UserHotkeysHelper {
  public userhotkeys = new Map<string, HotkeyActionNames>(
    userDefualtHotkeys.filter(
      hotkey => !browserDefaultHotkeys.some(dhk => dhk === hotkey[0])
    )
  );

  private get root() {
    return this.userExperience.root;
  }

  constructor(private userExperience: UserExperienceStore) {}

  private defaultActions = new Map<string, (() => void) | undefined>(
    Object.entries({
      [HotkeyActionNames.openKB]: () => {
        this.root.userExperience.ui.toggleHelpDialogVisibility();
      },
      [HotkeyActionNames.patientSearch]: undefined,
      [HotkeyActionNames.gotToAppointmentBook]: () => {
        this.root.routing.push(routes.calendarEvents.basePath.pattern);
      },
      [HotkeyActionNames.openWordProcessor]: () => {
        this.root.correspondence.ui.setShowTemplatePicker(true);
      },
      [HotkeyActionNames.goToClinicalTasks]: () => {
        this.root.routing.push(routes.tasks.clinical.pattern);
      },
      [HotkeyActionNames.goToInbox]: () => {
        this.root.routing.push(routes.userInbox.basePath.pattern);
      }
    })
  );

  private currentActions = new Map(this.defaultActions);

  /**
   * Map an action new action to an action name
   * @param actionName - the name of action to update
   * @param action - the new action that should be mapped for that action name
   */
  public registerAction(actionName: string, action: () => void) {
    this.currentActions.set(actionName, action);
    return () => this.unRegisterAction(actionName);
  }

  /**
   * reset an action to it's default action
   * @param actionName - the name of action to reset
   */
  private unRegisterAction(actionName: string) {
    this.currentActions.set(actionName, this.defaultActions.get(actionName));
  }

  private getAction(actionName: string) {
    return this.currentActions.get(actionName);
  }

  public handleKeydown(
    event: KeyboardEvent,
    trackEvent: (eventData: object) => void
  ) {
    // Do not handle shortcuts when locked dialog is showing.
    if (!!localStorage.getItem(StorageProperties.locked)) return;
    for (const [hotkey, actionName] of this.userhotkeys) {
      const match = Hotkey.isHotkey(hotkey, event);
      if (match) {
        const action = this.getAction(actionName);
        event.preventDefault();
        action && action();
        trackEvent({
          Hotkey: hotkey,
          Action: actionName
        });
        break;
      }
    }
  }
}
